import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Badges = () => {
  const width = 160;
  return (
    <div className="row p-0">
      <div
        className="p-0"
        style={{ display: "flex", justifyContent: "space-around" }}
      >
        <a
          href="https://www.credly.com/badges/8491dacc-12d1-4635-a49c-a6fe507ba022"
          target="_blank"
          rel="noreferrer"
          alt="AWS Certified Database – Specialty badge"
        >
          <StaticImage
            src="../badges/aws-db-192.png"
            width={width}
            alt="AWS Certified Database – Specialty badge"
          />
        </a>
        <a
          href="https://www.credly.com/badges/576e62a6-b2db-4b2c-b225-168d0990d875"
          target="_blank"
          rel="noreferrer"
          alt="AWS Certified Solutions Architect - Professional badge"
        >
          <StaticImage
            src="../badges/sa-pro-192.png"
            width={width}
            alt="AWS Certified Solutions Architect - Professional badge"
          />
        </a>
        <a
          href="https://www.credly.com/badges/851abee2-2223-4538-a92b-3972be0a1762"
          target="_blank"
          rel="noreferrer"
          alt="Certified Kubernetes Administrator badge"
        >
          <StaticImage
            src="../badges/cka-192.png"
            width={width}
            alt="Certified Kubernetes Administrator badge"
          />
        </a>
      </div>
    </div>
  );
};

export default Badges;
