import { Link } from "gatsby";
import React from "react";
import "./Header.css";

const Header = ({ active = "" }) => {
  return (
    <>
      <nav className="header-navbar">
        <div className="header-brand-title">
          <Link className="h3" to="/">
            Doing<span className="navbar-cloud">Cloud</span>Stuff.com
          </Link>
        </div>

        <div className="header-navbar-links">
          <ul>
            {/* <li key="projects">
              <Link
                className={(active === "projects" && `h5 active`) || "h5"}
                to="/projects"
              >
                Projects
              </Link>
            </li> */}
            <li key="posts" className="me-4">
              <Link
                className={(active === "posts" && `h4 active`) || "h4"}
                to="/posts"
              >
                Blog posts
              </Link>
            </li>
            <li key="about">
              <Link
                className={
                  (active === "about" && `h4 px-0 active`) || "h4 px-0"
                }
                to="/about"
              >
                About me
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <hr className="linebreak" />
    </>
  );
};

export default Header;
