import * as React from "react";
import Badges from "../components/Badges";
import Layout from "../components/Layout";

const jumbotron = {
  padding: "1em",
  paddingTop: "0",
  margin: "0",
  marginBottom: "100px",
  backgroundColor: "rgba(142, 227, 253, 0.3)",
};

// markup
const IndexPage = () => {
  return (
    <Layout>
      <title>Home | DoingCloudStuff</title>
      {/* hero section */}
      <div style={jumbotron}>
        <h1 className="display-4 mt-5 py-5">Hi, I'm Vincent!</h1>
        <Badges />
        <hr className="my-5" />
        <p className="display-6 text-center mt-5">Welcome to my blog!</p>
        <p className="display-6 text-center mt-4">
          Here, I write about the various things I picked up about cloud
          computing (and perhaps other topics as well) while working as a cloud
          developer!
        </p>
      </div>
    </Layout>
  );
};

export default IndexPage;
