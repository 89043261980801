import React from "react";
import Header from "./Header";

const Layout = ({ active = null, children }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col"></div>
        <div className="col-12 col-md-10 col-lg-8">
          <Header active={active} className="mb-5" />
          {children}
        </div>
        <div className="col"></div>
      </div>
    </div>
  );
};

export default Layout;
